import { company1, company2, company3, company4, company5, company6, company7 } from '../images/index';
import { nanoid } from 'nanoid';

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css'
import 'swiper/css/pagination';

import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

interface CompaniesState {
  image: string,
  link: string
}

const PartnerShip = ({isDarkMode}: {isDarkMode: boolean}) => {
  const companiesArr: CompaniesState[] = [
    {
      image: company1,
      link: "https://www.realist.md/"
    },
    {
      image: company2,
      link: "https://medfamily.md/"
    },
    {
      image: company3,
      link: "https://sidalgrup.md/"
    },
    {
      image: company4,
      link: "https://pas.md/ro/PAS"
    },
    {
      image: company5,
      link: "https://diolsem.md/"
    },
    {
      image: company6,
      link: "https://novaporta.md/"
    },
    {
      image: company7,
      link: "https://masterdoors.md/"
    },
  ]
  const storedLanguage = localStorage.getItem('language');

  return (
    <div className="partnership">
      <h1>{
        storedLanguage === 'en' ? 'Our Partners' : 
        storedLanguage === 'ro' ? 'Partenerii Noștri' : 
        'Наши Партнеры'
      }</h1>
     <div className={`swiper-container ${isDarkMode ? '' : 'light'}`}>
      <Swiper
       spaceBetween={50}
       autoplay={{
         delay: 1500,
         disableOnInteraction: false,
       }}
      slidesPerView={'auto'}
      modules={[Autoplay]}
       centeredSlides={false}
       className={`mySwiper`}
        >
        {companiesArr.map(({image, link}, index) => (
          <SwiperSlide key={nanoid()}>
            <Link to={link} target='_blank'>
            <img 
            src={image} 
            alt={`Partner ${index + 1}`} 
            className="swiper-img" />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
);
};

export default PartnerShip;
