import { constantsEN, constantsRO, constantsRU } from "./constants";
import { ConstantState } from "./types";

let languageConstant :ConstantState[];

const storedLanguage = localStorage.getItem('language');

switch (storedLanguage) {
  case 'ro':
    languageConstant = constantsRO;
    break;
  case 'ru':
    languageConstant = constantsRU;
    break;
  case 'en':
  default:
    languageConstant = constantsEN;
    break;
}

export { languageConstant };
