import { FaTelegram, FaPhone, FaWhatsapp, FaEnvelope, FaFacebook, FaInstagram, FaViber } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { nanoid } from "nanoid";
import { ConstantState } from "../types";
import { Link } from "react-router-dom";
import { languageConstant } from "../constantLanguages";
import { useState, useEffect } from "react";
import { logo1, logo2 } from "../images";
import { scrollToTop } from "../utils/utils";
import {bg, lightBg} from '../images/index'

const Footer = () => {
  const savedLanguage = localStorage.getItem('language')

  const [isDarkMode,setIsDarkMode] = useState(localStorage.getItem('theme'))

  useEffect(() => {
    const newMode = localStorage.getItem('theme')
    setIsDarkMode(newMode)
  },[localStorage.getItem('theme')])

  return (
  <footer 
  className={`footer ${isDarkMode==='dark' ? '' : 'light'}`}
  style={{
    backgroundSize: "114vw",
    backgroundPosition: "center"
  }}
  >
  <div className="footer-left">
    <div className="footer-logo">
      <div className="footer-logo__up">
        <Link to={"/"}> 
          <img 
          src={isDarkMode==='dark' ? logo2 : logo1} 
          alt="Footer Logo" 
          style={{width: "60px", borderRadius: "20%"}}
          onClick={scrollToTop}
          />
        </Link>
          <h2>
          {savedLanguage === 'en' ?
          "The Digital World in Your Hands" : 
          savedLanguage === 'ru' ? 'Виртуальный мир в твоих руках' :
          "Lumea digitală în mâinile tale"}
        </h2>
      </div>
      <p className="footer-logo__text">
      {savedLanguage === 'en' ? 'ThinkIT is a company that provides services for the maintenance of the IT infrastructure of our clients. Do you need computers, servers, networks in your company to work flawlessly - contact us!' :
        savedLanguage === 'ro' ? 'ThinkIT este o companie care oferă servicii de întreținere a infrastructurii IT a clienților noștri. Aveți nevoie de computere, servere, rețele în compania dvs. pentru a funcționa impecabil - contactați-ne!' : 
        'ThinkIT — компания, предоставляющая услуги по обслуживанию ИТ-инфраструктуры наших клиентов. Вам нужно, чтобы компьютеры, серверы, сети в вашей компании работали безупречно - обратитесь к нам!'}
      </p>
    </div>

    <div className="footer-links">
        <div className="footer-links__hrefs">
          <Link target="_blank" to="https://telegram.me/+37378188245"><FaTelegram className="icon"/></Link>
          <Link target="_blank" to="https://api.whatsapp.com/send/?phone=37378188245&text&type=phone_number&app_absent=0"><FaWhatsapp className="icon"/></Link>
          <Link 
          target="_blank"
          to="https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrgltWnHRnMWGCGBghsGfncLNFFNgcCQttXTKjVPlhlQsFHGPjQjBKmnWMtHFvwHwFxSBV"
          >
            <FaEnvelope className="icon"/>
        </Link>
        <Link target="_blank" to="viber://chat?number=37378188245"><FaViber className="icon"/></Link>
        <Link target="_blank" to="https://www.facebook.com/share/caYHhD71rUKoXxyF/?mibextid=JRoKGi"><FaFacebook className="icon"/></Link>
        <Link target="_blank" to="https://www.instagram.com/think_it_md/"><FaInstagram className="icon"/></Link>
        </div>
      </div>
  </div>
  <div className="footer-right">
  <div className="footer-links__nohrefs">
      <div className="footer-links__phone">
        <FaPhone className="icon"/>
        <span>(+373)-78-188-245</span>
      </div>
      <div className="footer-links__email">
          <MdEmail className="icon"/>
          <span>info@thinkit.md</span>
      </div>
    </div>
  <div className="footer-services">
    {
      languageConstant.map((constant: ConstantState) => (
        <Link to={constant.title} key={nanoid()}>{constant.title}</Link>
      ))
    }
  </div>  
  </div>
</footer>
  )
}
export default Footer