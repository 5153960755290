import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';

const About = ({isDarkMode}: {isDarkMode: boolean}) => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>({});

  const toggleSection = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const savedLanguage = localStorage.getItem('language')

  const elements = [
    { id: 'section1',
      title: savedLanguage === 'en' ? 'Our Mission' : savedLanguage === 'ro' ? 'Misiunea Noastră' : 'Наша Миссия',
      text: savedLanguage === 'en' ?
       'At ThinkIT, our mission is to provide exceptional IT services that empower businesses to achieve their goals. We are committed to delivering reliable, efficient, and innovative solutions that enhance productivity and drive success.' :
        savedLanguage === 'ro' ?
        'La ThinkIT, misiunea noastră este să oferim servicii IT excepționale, care să permită companiilor să își atingă obiectivele. Ne angajăm să oferim soluții fiabile, eficiente și inovatoare care sporesc productivitatea și stimulează succesul.' :
        'Наша миссия в ThinkIT — предоставлять исключительные ИТ-услуги, которые помогают предприятиям достигать своих целей. Мы стремимся предоставлять надежные, эффективные и инновационные решения, которые повышают производительность и способствуют успеху.'},
        {
          id: 'section2',
          title: savedLanguage === 'en' ? 'Provided Services' : savedLanguage === 'ro' ? 'Servicii Furnizate' : 'Предоставляемые Услуги',
          text: savedLanguage === 'en' ?
            'We offer a range of IT services including infrastructure maintenance, IT modernization, outsourcing, cloud services, CRM implementation, and engineering projects. Our team is equipped to handle all your IT needs, ensuring seamless and efficient operations.' :
            savedLanguage === 'ro' ?
            'Oferim o gamă de servicii IT, inclusiv întreținerea infrastructurii, modernizarea IT, outsourcing, servicii cloud, implementarea CRM și proiecte de inginerie. Echipa noastră este echipată pentru a gestiona toate nevoile dvs. IT, asigurând operațiuni fără probleme și eficiente.' :
            'Мы предлагаем широкий спектр ИТ-услуг, включая обслуживание инфраструктуры, модернизацию ИТ, аутсорсинг, облачные сервисы, внедрение CRM и инженерные проекты. Наша команда оснащена всем необходимым для решения всех ваших ИТ-задач, обеспечивая бесперебойную и эффективную работу.'
        },
      {
        id: 'section3',
        title: savedLanguage === 'en' ? 'Relationships' : savedLanguage === 'ro' ? 'Relații' : 'Отношения' ,
        subSections:[
          {
            count: '20',
            subText: savedLanguage === 'en' ? 'Satisfied Companies' : savedLanguage === 'ro' ? 'Companii Satisfăcute' : 'Довольные Компании'
          },
          {
            count: '5000',
            subText: savedLanguage === 'en' ? 'Helped Clients' : savedLanguage === 'ro' ? 'Clienți Ajutați' : 'Помощь Клиентам'
          },
          {
            count: '6',
            subText: savedLanguage === 'en' ? 'Partners' : savedLanguage === 'ro' ? 'Parteneri' : 'Партнеры'
          }
      ]
     },
  ]
  return (
    <section className={`about ${isDarkMode ? '' : 'light'}`}>
      <div className="about-left">
        <div className="about-left__info">
          <h1 className="about-title">
            {savedLanguage === 'en' ? 'About Us' : savedLanguage === 'ro' ? 'Despre Noi' : 'О нас'}
          </h1>
          <p className="about-text">
            {
              savedLanguage === 'ro' ? 'ThinkIT este specializat în întreținerea, crearea și modernizarea infrastructurii IT pentru clienții noștri. Ne asigurăm că computerele, serverele și rețelele dvs. funcționează impecabil, permițându-vă să vă concentrați exclusiv asupra afacerii dvs. Aveți încredere în ThinkIT pentru soluții IT complete, integrare perfectă și asistență excepțională, adaptată nevoilor dumneavoastră.' : 
              savedLanguage === 'en' ? 'ThinkIT specializes in maintaining, creating, and modernizing IT infrastructure for our clients. We ensure that your computers, servers, and networks operate flawlessly, allowing you to focus solely on your business. Trust ThinkIT for comprehensive IT solutions, seamless integration, and exceptional support tailored to your needs.' : 'ThinkIT специализируется на обслуживании, создании и модернизации ИТ-инфраструктуры для наших клиентов. Мы гарантируем безупречную работу ваших компьютеров, серверов и сетей, что позволит вам сосредоточиться исключительно на своем бизнесе. Доверьтесь ThinkIT, чтобы получить комплексные ИТ-решения, плавную интеграцию и исключительную поддержку, адаптированную к вашим потребностям.'
            }
           
          </p>
        </div>
        <div className="about-left__elements">
          {elements.map((section) => (
            <article
              key={section.id}
              className={`about-element ${openSections[section.id] ? 'active' : ''}`}
            >
              <div className="about-element__title">
                <h3>{section.title}</h3>
                <button onClick={() => toggleSection(section.id)}>
                  {openSections[section.id] ? '-' : '+'}
                </button>
              </div>
                { section.text ? (
                  <p className="about-element__text">{section.text}</p>
                ) : (
                  <article className='about-element__article'>
                    {section.subSections?.map(subSection => (
                      <div className='about-element__div'
                      key={nanoid()}
                      >
                        <h3>{subSection.count}+</h3>
                        <p>{subSection.subText}</p>
                      </div>
                    ))}
                  </article>
                )
                }
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;