import { nanoid } from "nanoid";
import { ConstantState } from "../types";
import { languageConstant } from "../constantLanguages";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const Services = ({isDarkMode} : {isDarkMode: boolean}) => {
    const storedLanguage = localStorage.getItem('language');

  return (
    <section className="header-services" id="services">
      {
        languageConstant.map((constant: ConstantState,idx: number) => {
          const {title,image} = constant

          return (
            <div className='flip-card' key={nanoid()}>
              <div className={`flip-card-inner ${isDarkMode ? '' : 'light'}`}>
                <div 
                className="flip-card-front"
                >
                  <img 
                  src={image} 
                  alt={`cardImage-${idx}`} 
                  className="card-img"  
                  />   
                      <h1 className="card-front__title">{title}</h1>
                      <h1 className="card-idx">{idx + 1 < 10 ? `0${idx + 1}` : idx + 1}</h1>
                </div>
                <div className="flip-card-back">
                    <h1 className="card-back__title">{title}</h1>
                    <Link 
                    to={`/${title}`}
                    className="card-back__link"
                    >
                    { storedLanguage === 'en' ? 'View Service' :
                    storedLanguage === 'ro' ? 'Află Mai Multe' :
                    'Узнай больше'
                    }
                    <FaArrowRight />
                    </Link>
                    <h1 className="card-back-idx">{idx + 1 < 10 ? `0${idx + 1}` : idx + 1}</h1>
                </div>
              </div>
            </div>
          )
        })
      }
    </section>
  )
}

export default Services