import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Service from "./components/Service";
import Footer from './components/Footer';
import ScrollTopBtn from "./components/ScrollTopBtn";

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true)
  
  return (
    <Router>
    <div className={`App ${isDarkMode ? '' : 'light'}`}>
      <Navbar 
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      /> 
      <Routes>
        <Route path="/" element={<Header isDarkMode={isDarkMode}/>} />
        <Route path="/:service" element={<Service isDarkMode={isDarkMode}/>} />
      </Routes>
      <Footer />
      <ScrollTopBtn />
    </div>
  </Router>
  );
}

export default App;
