import { FaInfinity, FaLightbulb, FaShieldAlt } from "react-icons/fa";

const WhyUs = ({isDarkMode}: {isDarkMode: boolean}) => {

  const savedLanguage = localStorage.getItem('language')

  return (
    <div className={`why ${isDarkMode ? '' : 'light'}`}>
  <div className="why-upper">
    <h1 className="why-upper__title">
      {savedLanguage === 'en' ? 'Why Us' : savedLanguage === 'ro' ? 'De ce noi' : 'Почему мы'}
    </h1>
    <p className="why-upper__text">
      {savedLanguage === 'en' ? 
        'Discover the reasons why our services stand out in the industry. We are committed to excellence, innovation, and security.' :
        savedLanguage === 'ro' ? 
        'Descoperiți motivele pentru care serviciile noastre se remarcă în industrie. Ne angajăm la excelență, inovație și securitate.' :
        'Узнайте, почему наши услуги выделяются в отрасли. Мы привержены превосходству, инновациям и безопасности.'}
    </p>
  </div>
  <div className="why-lower">
    <div className="why-articles">
    <article className="why-article">
      <FaInfinity className="why-article__icon" />
      <div className="why-article__info">
        <h3>
          {savedLanguage === 'en' ? 'Unlimited Potential' : savedLanguage === 'ro' ? 'Potencial Nelimitat' : 'Неограниченный Потенциал'}
        </h3>
        <p>
          {savedLanguage === 'en' ? 
            'We push the boundaries of what\'s possible, constantly innovating to offer solutions that exceed expectations.' :
            savedLanguage === 'ro' ? 
            'Împingem limitele a ceea ce este posibil, inovând constant pentru a oferi soluții care depășesc așteptările.' :
            'Мы расширяем границы возможного, постоянно внедряя инновации для предоставления решений, превышающих ожидания.'}
        </p>
      </div>
    </article>
    <article className="why-article">
      <FaLightbulb className="why-article__icon" />
      <div className="why-article__info">
        <h3>
          {savedLanguage === 'en' ? 'Innovative Ideas' : savedLanguage === 'ro' ? 'Idei Inovatoare' : 'Инновационные Идеи'}
        </h3>
        <p>
          {savedLanguage === 'en' ? 
            'Our team is dedicated to bringing fresh and creative ideas to the table, ensuring you stay ahead of the curve.' :
            savedLanguage === 'ro' ? 
            'Echipa noastră este dedicată aducerii de idei proaspete și creative, asigurându-vă că rămâneți înaintea curbei.' :
            'Наша команда стремится приносить свежие и креативные идеи, обеспечивая вам опережение конкурентов.'}
        </p>
      </div>
    </article>
    <article className="why-article">
      <FaShieldAlt className="why-article__icon" />
      <div className="why-article__info">
        <h3>
          {savedLanguage === 'en' ? 'Reliable Security' : savedLanguage === 'ro' ? 'Securitate Fiabilă' : 'Надежная Безопасность'}
        </h3>
        <p>
          {savedLanguage === 'en' ? 
            'We prioritize your safety and security, implementing robust measures to protect your assets and data.' :
            savedLanguage === 'ro' ? 
            'Prioritizăm siguranța și securitatea dvs., implementând măsuri robuste pentru a proteja activele și datele dvs.' :
            'Мы ставим на первое место вашу безопасность, внедряя надежные меры для защиты ваших активов и данных.'}
        </p>
      </div>
    </article>
    </div>
    <h2 className="why-lower__title">
    {savedLanguage === 'en' ? "Improving Your Company Byte by Byte" : savedLanguage === 'ro' ? "Îmbunătățirea Companiei Dumneavoastră Byte cu Byte" : "Улучшение Вашей Компании Байт за Байтом"}
  </h2>
  </div>
</div>

  );
}

export default WhyUs;
