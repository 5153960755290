import { error } from './images/index'
import { Link } from 'react-router-dom'

const NotFound = ({isDarkMode} : {isDarkMode: boolean}) => {
  const languageConstant = localStorage.getItem("language")

  return (
      <div className={`notFound ${isDarkMode ? "" : 'light'}`}>
        <img src={error} alt="404" />
        <div className="notFound-info">
            <h1>{languageConstant === 'en' ? 
            "Uh oh. This page doesn't exist." : 
            languageConstant === 'ru' ? 
            "Ой-ой. Эта страница не существует." : 
            "Această pagină nu există."
          }</h1>
            <p>
              { languageConstant === 'en' ? 
              "Head to our homepage that does exist as this one decided to take a day off. Who can blame it?" : languageConstant === 'ru' ?
               "Перейдите на нашу главную страницу, которая существует, так как эта решила взять выходной. Кто может ее винить?" :
               "Mergeți la pagina noastră principală care există, deoarece aceasta a decis să ia o zi liberă. Cine o poate învinovăți?"
              }
            </p>
        </div>
        <Link 
        to="/" 
        className={`btn notFound-btn ${isDarkMode ? '' : 'light'}`}>
          {languageConstant === 'en' ? 
          "Back to Homepage" : languageConstant === 'ro' ? 
          "Înapoi Acasă" : 
          "Главная Страница"
          }
        </Link>
    </div>
  )
}

export default NotFound