import { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { scrollToTop } from "../utils/utils";

const ScrollTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button 
      onClick={scrollToTop} 
      className={`scrollBtn ${isVisible ? 'visible' : ''}`}
    >
      <FaArrowUp />
    </button>
  );
};

export default ScrollTopBtn;
