import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { Link } from 'react-router-dom';
import { languageConstant } from '../constantLanguages';
import { ConstantState } from '../types';
import { nanoid } from 'nanoid';
import Logo from './Logo';
import { FaSun,FaMoon } from 'react-icons/fa';
import { scrollToBottom } from '../utils/utils';

interface Props {
   isDarkMode: boolean, 
   setIsDarkMode: Dispatch<SetStateAction<boolean>>
  }

const Navbar = ({setIsDarkMode, isDarkMode} : Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isPagesHovered, setIsPagesHovered] = useState<boolean>(false);

  const [language, setLanguage] = useState<string>
  ('en');

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && ['en', 'ru', 'ro'].includes(savedLanguage)) {
      setLanguage(savedLanguage);
    }
  }, []);
  
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    setIsDarkMode(savedTheme === 'dark');
  }, []);
  
  const toggleMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('theme', newMode ? 'dark' : 'light');
  };

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  const getLanguageConstants = () => {
    switch (language) {
      case 'ru':
        return "RU"
      case 'ro':
        return "RO"
      case 'en':
      default:
        return "EN"
    }
  };

  const toggleLanguage = () => {
    const newLanguage = language === 'ru' ? 'en' : language === 'en' ? 'ro' : 'ru';
    handleLanguageChange(newLanguage);
    window.location.reload()
  };

  const buttonText = getLanguageConstants();

  const PageElement = () => (
      <ul>
        {languageConstant.map((service: ConstantState) => (
          <li key={nanoid()}>
            <Link to={`/${service.title}`} onClick={() => {
              setIsOpen(false)
              setIsPagesHovered(false)
            }}
            className={`${isDarkMode ? '' : 'light'}`}
            >
              {service.title}
            </Link>
          </li>
        ))}
      </ul>
  );

  const ThemeSwitcher = () => (
    <div
      className={`mode-switcher ${isDarkMode ? '' : 'light'}`}
      onClick={toggleMode}
    >
      {isDarkMode ? <FaMoon className='icon'/> : <FaSun className='icon'/>}
    </div>
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className={`navbar ${isDarkMode ? '' : 'light'}`}>
      <Link to={"/"} className='navbar-link'>
        <Logo width={'55px'}/>
        <h1 className={`${isDarkMode ? '' : 'light'}`}>Think IT</h1>
      </Link>
      {windowWidth <= 1024 ? (
        <div>
          <div 
          className="hamburger-menu" 
          onClick={() => setIsOpen(!isOpen)}>
            <div className={`line ${isOpen ? 'active' : ''}`} />
            <div className={`line ${isOpen ? 'active' : ''}`} />
            <div className={`line ${isOpen ? 'active' : ''}`} />
          </div>
          {isOpen && (
            <div className='menu-overlay'>
              <div className="menu-items">
                <button 
                className='btn' 
                onClick={() => {
                  setIsOpen(false)
                  scrollToBottom()
                }}>
                {language === 'en' ?
                "Contact Us" : 
                language === 'ru' ? 'Свяжитесь с нами' :
                "Contactaţi-ne"
                }</button>
              <button 
              className='btn' 
              style={{ width: "200px" }}
              onClick={() => setIsPagesHovered(!isPagesHovered)}
              >
              {
                language === 'en' ?
                "Pages" : 
                language === 'ru' ? 'Страницы' :
                "Pagini"
              }
              </button>
              <button 
              className='btn'
              style={{ width: "70px" }}
              onClick={toggleLanguage}
              >
                {buttonText}
              </button>
              <div className={`pages-dropdown ${isPagesHovered ? 'active' : ''}`}>
                <PageElement /> 
              </div>
              <ThemeSwitcher />
              </div>
            </div>
          )}
        </div>
      ) : (
         <div 
          className="navbar-buttons" >
              <ThemeSwitcher />
            <button 
            className={`navbar-pages btn ${isDarkMode ? '' : 'light'}`} 
            style={{ width: "200px" }}
            onClick={() => setIsPagesHovered(!isPagesHovered)}
            >
              {
              language === 'en' ?
              "Pages" : 
              language === 'ru' ? 'Страницы' :
              "Pagini"
              }
            </button>
              <button 
              className={`navbar-lang btn ${isDarkMode ? '' : 'light'}`} 
              style={{ width: "70px" }}
              onClick={toggleLanguage}
              >
              {buttonText}
              </button>
            <button 
            className={`navbar-contact btn ${isDarkMode ? '' : 'light'}`}
            onClick={scrollToBottom}
            >
              {
              language === 'en' ?
              "Contact Us" : 
              language === 'ru' ? 'Свяжитесь с нами' :
              "Contactaţi-ne"
              }
            </button>

          <div className={`pages-dropdown ${isPagesHovered ? 'active' : ''}`}>
            <PageElement /> 
          </div>
         </div>
      )}
    </nav>
  );
}

export default Navbar;
 