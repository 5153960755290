import { languageConstant } from "../constantLanguages";
import { useParams } from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css'
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'
import { ConstantState, SubConstantState } from "../types";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { scrollToTop } from "../utils/utils";
import NotFound from "../NotFound";

interface Props {
  isDarkMode: boolean,
  singleService: SubConstantState,
  idx:number,
  image: string,
  title: string
}

const ServiceCard = ({singleService, idx, image, title, isDarkMode}: Props) => {

  useEffect(() => {
    scrollToTop()
  },[])
  
  return (
    <VerticalTimelineElement 
    className={`${isDarkMode ? '' : 'light'}`}
    iconStyle={{background: image}}
    icon={null}
      date={title}
      >
      <div className="service-info">
        <h1 className="service-title">{singleService.subTitle}</h1>
        <p className="service-text">{singleService.subText}</p>
        <h1 className="service-idx">{idx+1}</h1>
      </div>
    </VerticalTimelineElement>
  )
}

const Service = ({isDarkMode} : {isDarkMode: boolean}) => {
  const { service } = useParams<{ service: string }>();
  const serviceToFind: ConstantState | undefined = languageConstant.find(constant => constant.title === service) 

  if (!serviceToFind) {
    return <NotFound isDarkMode={isDarkMode}/>;
  }

  return (
    <VerticalTimeline className={`${isDarkMode ? '' : 'light'}`}>
      {serviceToFind && (
        serviceToFind.subSection.map((section:SubConstantState,idx: number) => (
          <ServiceCard 
          isDarkMode={isDarkMode}
          key={nanoid()} 
          idx={idx} 
          singleService={section}
          title={serviceToFind.title}
          image={serviceToFind.image}
          />)
        ) 
      )}
    </VerticalTimeline>
  )
}

export default Service