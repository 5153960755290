import { useEffect, useState } from "react";
import { logo1, logo2 } from "../images";
import { scrollToTop } from "../utils/utils";

interface Props {
  width: string;
}

const Logo = ({ width }: Props) => {
  const [isLogoOpen, setIsLogoOpen] = useState<boolean>(false);
  const [isDarkMode,setIsDarkMode] = useState(localStorage.getItem('theme'))

  useEffect(() => {
    const newMode = localStorage.getItem('theme')
    setIsDarkMode(newMode)
  },[localStorage.getItem('theme')])

  const handleLogoClick = () => {
    setIsLogoOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsLogoOpen(false);
  };

  return (
    <div className="logo-container" onClick={scrollToTop}>
      {isLogoOpen && (
        <div className="logo-modal show" onClick={handleCloseModal}>
          <img src={isDarkMode ==='dark' ? logo1 : logo2} alt="Big Logo" className="big-logo" />
        </div>
      )}

      <img
        src={isDarkMode === 'light' ? logo1 : logo2}
        alt="Small Logo"
        className="small-logo"
        style={{ width: width }}
        onClick={handleLogoClick}
      />
    </div>
  );
};

export default Logo;
